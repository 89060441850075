<template>
  <header id="header">
    <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top">
      <a class="navbar-brand" href="/report">hachidori popup</a>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0" />
        <form class="form-inline my-2 my-lg-0">
          <p class="username">ID：{{ username }}</p>
          <button class="btn btn-outline-success my-2 my-sm-0" @click="logout">
            <font-awesome-icon icon="user-alt" />
            ログアウト
          </button>
        </form>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      username: ""
    };
  },
  mounted() {
    this.username = this.$store.getters.user.login_id;
  },
  methods: {
    logout() {
      let user = {};
      user.login_status = 0;
      this.$store.commit("setUser", user);
      this.$router.replace("/logout");
    }
  }
};
</script>

<style scoped>
.fixed-top {
  border: solid 1px #c0c0c0;
}

.navbar {
  padding: 1.5rem 1rem;
}

.navbar-brand {
  font-weight: bold;
}

.bg-white {
  background-color: #f7f8fd !important;
}

.username {
  font-weight: 100;
  margin-bottom: 0;
  padding-right: 30px;
  font-size: 20px;
}
</style>
