<template>
  <div class="link">
    <div class="link_section">
      <div class="container">
        <loading :active.sync="isLoading" :is-full-page="fullPage" />
      </div>
    </div>
    <div class="link_section">
      <h3>ボタン設定</h3>
      <p class="link_text">
        バナー画像下部に表示されるボタンの設定をしてください。
      </p>
      <div class="link_section-detail">
        <div class="link_section-detail1">
          <h5>●文言設定</h5>
          <div v-if="isSetMessage">
            <div class="input-group input-group-lg input2">
              <input
                type="text"
                class="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-lg"
                v-model="message"
                placeholder="例：お得な情報はこちら！"
              />
              <button
                type="button"
                class="btn btn-info"
                @click="confUpload"
                v-bind:disabled="isPush2"
              >
                設定
              </button>
            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="col-sm-2">
                <label>設定中</label>
              </div>
              <div class="col-sm-5">
                <p class="link_message">{{ message }}</p>
              </div>
              <div class="col-sm-3">
                <button
                  type="button"
                  class="btn btn-info"
                  @click="messageRewrite"
                >
                  編集
                </button>
              </div>
            </div>
          </div>
          <p class="link_text-detail">
            ※20文字が最大文字数になります。
          </p>
        </div>
        <div class="link_section-detail2">
          <h5>●カラー設定</h5>
          <v-swatches
            v-model="color"
            :trigger-style="{ width: '150px', height: '50px' }"
            :show-labels="true"
            :show-fallback="true"
            :show-border="true"
            @close="setColor"
          />
        </div>
      </div>
      <div class="link_section-detail">
        <h5>●バナー・文言のプレビュー</h5>
        <div v-if="isPreview">
          <div class="preview_screen">
            <div class="preview_screen_image">
              <p>
                ここには<br />[ バナー設定 ]画面で設定した<br />画像が表示されます。
              </p>
            </div>
            <div class="preview_screen_link">
              <a href="https://test.com" class="preview_screen_link_url">
                お得な情報はこちら！
              </a>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="set_screen">
            <div class="set_screen_image">
              <img :src="image_path" />
            </div>
            <div class="set_screen_link">
              <a
                :href="link"
                class="set_screen_link_url"
                :style="{ background: color, color: textColor }"
              >
                {{ message }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VSwatches from "vue-swatches";

// Import the styles too, typically in App.vue or main.js
import "vue-swatches/dist/vue-swatches.css";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "Link",
  components: { VSwatches, loading: Loading },
  data() {
    return {
      link: "",
      message: "",
      color: "#1CA085",
      textColor: "white",
      image_path: "",
      isSetLink: true,
      isSetMessage: true,
      isPreview: true,
      isLoading: false,
      fullPage: true
    };
  },
  computed: {
    isPush1() {
      if (this.link !== "") {
        return false;
      } else {
        return true;
      }
    },
    isPush2() {
      if (this.message !== "") {
        return false;
      } else {
        return true;
      }
    }
  },
  mounted() {
    this.isLoading = true;
    this.axios
      .get(
        "https://irua384lo0.execute-api.ap-northeast-1.amazonaws.com/prod/getTagInfo",
        {
          params: {
            cid: this.$store.getters.user.cid
          }
        }
      )
      .then(result => {
        if (result.data !== null) {
          let data = JSON.parse(result.data.body);
          this.link = data.link_url;
          this.$store.commit("setLink", data.link_url);
          this.isSetLink = false;
          this.message = data.message;
          this.$store.commit("setMessage", data.message);
          this.isSetMessage = false;
          this.color = data.color;
          this.$store.commit("setColor", data.color);
          if (this.color === "#ffffff") {
            this.textColor = "#000000";
          }
          this.color = this.$store.getters.color;
          this.image_path = this.$store.getters.image_path;
          this.isPreview = false;
        } else {
          if (this.$store.getters.link !== null) {
            this.isSetLink = false;
          }
          if (this.$store.getters.message !== null) {
            this.isSetMessage = false;
          }
          if (this.$store.getters.color !== null) {
            this.color = this.$store.getters.color;
          }
        }
        this.isLoading = false;
      });
  },
  methods: {
    linkUpload() {
      this.isLoading = true;
      this.$store.commit("setLink", this.link);
      const params = {
        cid: this.$store.getters.user.cid,
        link: this.link
      };
      const options = {
        headers: { "Content-Type": "application/json;charset=utf-8" }
      };
      this.axios
        .post(
          "https://zhv4l5xx9f.execute-api.ap-northeast-1.amazonaws.com/prod/setLinkInfo",
          params,
          options
        )
        .then(() => {
          location.reload();
        })
        .catch(error => {
          console.error(error);
        });
    },
    confUpload() {
      this.isLoading = true;
      this.$store.commit("setMessage", this.message);
      const params = {
        cid: this.$store.getters.user.cid,
        message: this.message
      };
      const options = {
        headers: { "Content-Type": "application/json;charset=utf-8" }
      };
      this.axios
        .post(
          "https://3zxwd5lkgj.execute-api.ap-northeast-1.amazonaws.com/prod/setMessage",
          params,
          options
        )
        .then(res => {
          location.reload();
          console.log(res);
        })
        .catch(error => {
          console.log(error);
        });
    },
    setColor() {
      this.isLoading = true;
      this.$store.commit("setColor", this.color);
      const params = {
        cid: this.$store.getters.user.cid,
        color: this.color
      };
      const options = {
        headers: { "Content-Type": "application/json;charset=utf-8" }
      };
      this.axios
        .post(
          "https://g6h0xi8pdf.execute-api.ap-northeast-1.amazonaws.com/prod/setColor",
          params,
          options
        )
        .then(res => {
          location.reload();
          console.log(res);
        })
        .catch(error => {
          console.log(error);
        });
    },
    linkRewrite() {
      this.isSetLink = true;
      this.$store.commit("setLink", null);
    },
    messageRewrite() {
      this.isSetMessage = true;
      this.$store.commit("setMessage", null);
    }
  }
};
</script>

<style scoped>
.link {
  margin-left: 2rem;
}

.link_section {
  margin-bottom: 6rem;
}

.link_section-detail {
  content: "";
  display: block;
  clear: both;
}

.link_section-detail1 {
  width: 50%;
  float: left;
  margin-bottom: 3rem;
}

.link_section-detail2 {
  width: 50%;
  float: right;
}

h3 {
  text-align: left;
  font-weight: bold;
}

h5 {
  text-align: left;
  font-weight: bold;
  margin-top: 2.5rem;
  color: #808080;
}

.link_text {
  text-align: left;
  color: #808080;
}

.form-control {
  border-radius: 0.6rem !important;
  border: solid 2px #c0c0c0;
}

.form-control::placeholder {
  color: #dcdcdc;
}

.btn {
  border-radius: 0.6rem;
  margin-left: 0.5rem;
  padding: 0 1.7rem 0 1.7rem;
}

.input1 {
  margin-top: 0.8rem;
  width: 60%;
}

.input2 {
  margin-top: 0.8rem;
  width: 80%;
}

.link_text-detail {
  text-align: left;
  font-size: 0.8rem;
  color: #808080;
  margin-top: 0.8rem;
}

.vue-swatches {
  float: left;
}

label {
  border: solid 1px cadetblue;
  border-radius: 6px;
  color: cadetblue;
  padding: 1px 2px 1px 2px;
}

.link_url {
  display: block;
  font-size: 1.5rem;
  text-align: left;
}

.link_message {
  font-size: 1.2rem;
  text-align: left;
}

.preview_screen,
.set_screen {
  width: 900px;
  height: 500px;
  margin: 0 auto 0 0;
  background-color: #dcdcdc;
}

.preview_screen_image {
  width: 300px;
  height: 280px;
  margin: 0 auto;
  display: inline-block;
  margin-top: 70px;
  background-color: #f0f8ff;
}

.preview_screen_image p {
  display: inline-block;
  padding-top: 30%;
}

.preview_screen_link,
.set_screen_link {
  width: 300px;
  height: 80px;
  margin: 0 auto;
  background-color: white;
  padding-top: 15px;
  border-radius: 3px;
}

.preview_screen_link_url {
  width: 280px;
  height: 50px;
  margin: 0 auto;
  background-color: #1ca085;
  color: white;
  font-size: 1.3rem;
  text-align: center;
  line-height: 55px;
  top: 80px;
  box-shadow: 0px 3px #666666;
  font-weight: bold;
  border-radius: 3%;
  display: inline-block;
  text-decoration: none;
}

.set_screen_image {
  width: 300px;
  height: 280px;
  margin: 0 auto;
  display: inline-block;
  margin-top: 70px;
}

.set_screen_image img {
  width: 300px;
  height: 280px;
}

.set_screen_link_url {
  width: 280px;
  height: 50px;
  margin: 0 auto;
  color: white;
  font-size: 1.3rem;
  text-align: center;
  line-height: 55px;
  top: 80px;
  box-shadow: 0px 3px #666666;
  font-weight: bold;
  border-radius: 3%;
  display: inline-block;
  text-decoration: none;
}
</style>
