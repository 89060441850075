<template>
  <div class="embedded">
    <div class="container">
      <loading :active.sync="isLoading" :is-full-page="fullPage" />
    </div>
    <div class="row">
      <div class="col-md-10">
        <h3>チャットボット表示設定</h3>
        <p class="chatbot_text">"script"タグを削除して設定してください。</p>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="12"
          style="margin-top: 49px;"
          v-model="textarea"
        />
        <button type="button" class="btn btn-info" @click="sendText">
          設定
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";

export default {
  name: "Chatbot",
  components: { loading: Loading },
  data() {
    return {
      textarea: "",
      isShow: false,
      message: "",
      isLoading: false,
      fullPage: true
    };
  },
  mounted() {
    this.textarea = this.$store.getters.chatbot;
  },
  methods: {
    sendText() {
      this.isLoading = true;
      const params = {
        cid: this.$store.getters.user.cid,
        chatbot_text: this.textarea
      };
      const options = {
        headers: { "Content-Type": "application/json;charset=utf-8" }
      };
      this.axios
        .post(
          "https://oo1lx3cvba.execute-api.ap-northeast-1.amazonaws.com/prod/setChatbotText",
          params,
          options
        )
        .then(() => {
          this.$store.commit("setChatbot", this.textarea);
          location.reload();
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>

<style scoped>
h3,
p {
  text-align: left;
}

h3 {
  font-weight: 700;
}

.form-control:disabled {
  background-color: white;
}

.form-control {
  border: 2px solid #ced4da;
  border-radius: 0.5rem;
  width: 75%;
}

.embedded {
  margin-left: 50px;
}

.btn {
  margin: 0 0 0 13rem;
  padding: 0.5rem 3rem;
  margin-top: 2rem;
}

.vif-enter-active,
.vif-leave-active {
  transition: opacity 2s;
}

.vif-enter,
vif-leave-to {
  opacity: 0;
}
.chatbot_text {
  margin-top: 49px;
  margin-bottom: -49px;
}
</style>
