<template>
  <div class="sidebar">
    <div class="sidebar-wrapper">
      <div class="sidebar-link-area">
        <!-- サイドバーメニュー -->
        <div v-if="isSidebarShow && accountType === '1'">
          <p>
            <router-link
              to="/report"
              class="sidebar-link"
              style="padding-right: 40px;"
            >
              <font-awesome-icon icon="signal" />
              レポート
            </router-link>
          </p>
          <p>
            <router-link to="/embedded" class="sidebar-link">
              <font-awesome-icon icon="code" />
              埋め込み設定
            </router-link>
          </p>
          <p>
            <router-link to="/banner" class="sidebar-link">
              <font-awesome-icon icon="ad" />
              バナー設定
            </router-link>
          </p>
          <p>
            <router-link to="/link" class="sidebar-link">
              <font-awesome-icon icon="link" />
              リンク設定
            </router-link>
          </p>
          <p>
            <router-link to="/time" class="sidebar-link">
              <font-awesome-icon icon="stopwatch" />
              トリガー設定
            </router-link>
          </p>
        </div>
        <div v-else-if="isSidebarShow && accountType === '2'">
          <p>
            <router-link
              to="/chatbot_report"
              class="sidebar-link"
              style="padding-right: 40px;"
            >
              <font-awesome-icon icon="signal" />
              レポート
            </router-link>
          </p>
          <p>
            <router-link to="/chatbot_embedded" class="sidebar-link">
              <font-awesome-icon icon="code" />
              埋め込み設定
            </router-link>
          </p>
          <p>
            <router-link to="/banner" class="sidebar-link">
              <font-awesome-icon icon="ad" />
              バナー設定
            </router-link>
          </p>
          <p>
            <router-link to="/chatbot_link" class="sidebar-link">
              <font-awesome-icon icon="link" />
              リンク設定
            </router-link>
          </p>
          <p>
            <router-link to="/chatbot" class="sidebar-link">
              <font-awesome-icon icon="robot" />
              チャットボット設定
            </router-link>
          </p>
          <p>
            <router-link to="/time" class="sidebar-link">
              <font-awesome-icon icon="stopwatch" />
              トリガー設定
            </router-link>
          </p>
        </div>
        <div v-if="isShow">
          <button type="button" class="btn btn-info" @click="signup">
            アカウント発行
          </button>
          <button
            type="button"
            class="btn btn-info"
            @click="account"
            style="margin-top: 30px;"
          >
            アカウント管理
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      isSidebarShow: false,
      accountType: ""
    };
  },
  mounted() {
    if (this.$store.getters.user != null) {
      if (this.$store.getters.user.role === "admin") {
        this.isShow = true;
      }
      if (this.$store.getters.user.account_type == "1") {
        this.isSidebarShow = true;
        this.accountType = this.$store.getters.user.account_type;
      } else if (this.$store.getters.user.account_type == "2") {
        this.isSidebarShow = true;
        this.accountType = this.$store.getters.user.account_type;
      }
    }
  },
  methods: {
    signup() {
      this.$router.replace("/singup");
    },
    account() {
      this.$router.replace("/account");
    }
  }
};
</script>

<style>
.sidebar {
  border: solid 1px #c0c0c0;
  border-top: none;
  background-color: #f5f5f5;
  height: 100%; /* サイドバーの高さ */
  width: 200px; /* サイドバーのwidthを指定 */
  max-width: 200px; /* widthの最大値 */
  opacity: 0.9.5; /* 透過する 0に近くほど透過する */
  position: fixed; /* 左上に要素を固定する(スクロールしても位置は固定される) */
  overflow-x: hidden; /* 横軸ではみ出た要素を非表示にする */
  box-sizing: border-box; /* paddingとborderを、widthとheightに含める */
  margin-top: -60px;
}

.sidebar-link-area {
  padding-top: 50px; /* サイドバーリンクの上部に空白を作る */
}

.sidebar-link {
  color: #808080; /* リンクの文字色を白に */
  text-align: center;
  display: block;
  padding: 10px;
}

.sidebar-link:hover {
  color: #ffffff; /* マウスがリンクに乗った時も文字色を白に */
  background-color: #22a7f0;
  text-decoration: none;
  display: block;
  padding: 10px;
}

.router-link-active {
  color: #ffffff; /* マウスがリンクに乗った時も文字色を白に */
  background-color: #22a7f0;
  text-decoration: none;
  display: block;
  padding: 10px;
}

.sidebar-text {
  color: #22a7f0;
}

p {
  font-weight: bold;
}
</style>
