<template>
  <div class="report">
    <div class="report-header">
      <label style="line-height: 40px;">開始：</label>
      <Datepicker
        :language="ja"
        v-model="startDate"
        :format="customFormatter"
        :disabled-dates="disabledDates"
        @selected="startSelected"
      />
      <label style="line-height: 40px;">終了：</label>
      <Datepicker
        :language="ja"
        v-model="endDate"
        :format="customFormatter"
        :disabled-dates="disabledDates"
        @selected="endSelected"
      />
      <button class="btn btn-info" @click="fillData">
        表示
      </button>
      <button
        class="btn btn-info"
        @click="downloadCSV"
        style="margin: 0 0 0 auto;"
      >
        CSVダウンロード
      </button>
    </div>
    <MainChart
      class="chart"
      :chart-data="datacollection"
      :option="options"
      :height="height"
      :width="width"
    />
  </div>
</template>

<script>
import MainChart from "../components/Chart.vue";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import moment from "moment";

export default {
  name: "Report",
  components: { MainChart, Datepicker },
  data() {
    return {
      prevRoute: null,
      disabledDates: {
        from: new Date()
      },
      ja: ja,
      labels: [],
      impData: [],
      csvObject: [],
      datacollection: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 10
              }
            }
          ]
        }
      },
      height: window.innerHeight / 2,
      width: window.innerWidth / 2,
      startDate: "",
      endDate: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
      if (from.fullPath === "/login") {
        location.reload();
      }
    });
  },
  mounted() {
    if (
      this.$store.getters.startDate !== "" &&
      this.$store.getters.endDate !== ""
    ) {
      this.startDate = this.$store.getters.startDate;
      this.endDate = this.$store.getters.endDate;
      this.fillData();
    }
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    startSelected(date) {
      this.startDate = date.toLocaleString();
      this.$store.commit("setStartDate", this.startDate);
    },
    endSelected(date) {
      this.endDate = date.toLocaleString();
      this.$store.commit("setEndDate", this.endDate);
    },
    fillData() {
      // dataの初期化
      this.labels = [];
      this.impData = [];
      this.csvObject = [];
      if (this.startDate === "" || this.endDate === "") {
        alert("検索したい日付を選択してください。");
        return;
      }
      axios
        .get(
          "https://z13qu6g7rj.execute-api.ap-northeast-1.amazonaws.com/default/getReport",
          {
            params: {
              cid: this.$store.getters.user.cid,
              start: this.startDate.toLocaleString(),
              end: this.endDate.toLocaleString()
            }
          }
        )
        .then(res => {
          let datas = JSON.parse(res.data.body);
          for (let data of datas) {
            this.labels.push(data.date);
            this.impData.push(data.impressions);
            this.csvObject.push(
              data.date + "," + data.impressions + "," + data.click_num
            );
          }
          this.datacollection = {
            labels: this.labels,
            datasets: [
              {
                label: "インプレッション",
                data: this.impData,
                backgroundColor: "rgba(30, 144, 255, 0.6)",
                borderColor: "#6495ed",
                borderWidth: 1
              }
            ]
          };
        });
    },
    downloadCSV() {
      let csv = "\ufeff" + "日付, インプレッション数 \n";
      let line = "";
      for (let i = 0; i < this.csvObject.length; i++) {
        line += this.csvObject[i] + "\n";
      }
      csv += line;
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Report.csv";
      link.click();
    }
  }
};
</script>
<style>
.chart {
  max-width: 900px;
  margin: 10px auto;
}

.vdp-datepicker {
  display: inline-block;
  padding: 0.3rem;
  border-radius: 5%;
}

.report-header {
  display: flex;
}
</style>
