<template>
  <div class="account">
    <h2>アカウント管理</h2>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      @on-row-click="accountDelete"
    />
    <button
      type="button"
      class="btn btn-info"
      @click="backReport"
      style="margin-top: 25px;"
    >
      レポート画面へ戻る
    </button>
  </div>
</template>

<script>
export default {
  name: "Account",
  data() {
    return {
      columns: [
        {
          label: "id",
          field: "id"
        },
        {
          label: "アカウントタイプ",
          field: "type"
        },
        {
          label: "表示/非表示",
          field: "display"
        },
        {
          label: "作成日",
          field: "createdAt",
          type: "date",
          dateInputFormat: "yyyy/MM/dd hh:mm:ss",
          dateOutputFormat: "yyyy/MM/dd hh:mm:ss"
        }
      ],
      rows: []
    };
  },
  mounted() {
    this.axios
      .get(
        "https://d2vgtrrdmk.execute-api.ap-northeast-1.amazonaws.com/prod/getuserinfo",
        {
          params: {
            cid: this.$store.getters.user.cid
          }
        }
      )
      .then(result => {
        if (result.data !== null) {
          let data = JSON.parse(result.data.body);
          let pRow = {};
          for (let row of data) {
            pRow.id = row.login_id;
            if (row.account_type === "1") {
              pRow.type = "ポップアップ用";
            } else {
              pRow.type = "チャットボット用";
            }
            if (row.delete_flg === 0) {
              pRow.display = "表示";
            } else {
              pRow.display = "非表示";
            }
            pRow.createdAt = row.created_at;
            this.rows.push(pRow);
            pRow = {};
          }
        }
      });
  },
  methods: {
    accountDelete(el) {
      let message = {};
      if (el.row.display === "表示") {
        message = {
          body: el.row.id + "さんのポップアップを【非表示】にしますか?"
        };
      } else {
        message = {
          body: el.row.id + "さんのポップアップを【表示】にしますか?"
        };
      }
      let self = this;
      this.$dialog.confirm(message).then(function() {
        const params = {
          login_id: el.row.id
        };
        const options = {
          headers: { "Content-Type": "application/json;charset=utf-8" }
        };
        self.axios
          .post(
            "https://rqrv0iww31.execute-api.ap-northeast-1.amazonaws.com/prod/deleteUserInfo",
            params,
            options
          )
          .then(res => {
            location.reload();
            console.log(res);
          })
          .catch(error => {
            console.log(error);
          });
      });
    },
    backReport() {
      this.$router.replace("/report");
    }
  }
};
</script>

<style scoped>
.signup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #dcdcdc;
  z-index: 9999;
  padding-top: 5rem;
}

h2 {
  margin-bottom: 2.5rem !important;
}

.input-group {
  width: 70%;
  margin: 0 auto;
}

.form-control {
  border-radius: 0.6rem !important;
  margin-bottom: 2.5rem !important;
}

.btn {
  margin: auto;
  padding: 0.6rem 5rem;
  font-weight: bold;
}

#select {
  width: 70%;
  margin: 0 auto;
}
.confirm-button {
  margin-left: 15px !important;
}
</style>
