<template>
  <div class="embedded">
    <transition name="vif">
      <div v-if="isShow">
        <div class="alert alert-success" role="alert" style="text-align: left;">
          <font-awesome-icon icon="check" />{{ message }}をコピーしました。
        </div>
      </div>
    </transition>
    <div class="row">
      <div class="col-md-10">
        <h3>チャットボット設定用タグ</h3>
        <p>
          チャットボットを表示するページの<br />
          &lt;body&gt;と&lt;/body&gt;の間に挿入してください。
        </p>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="12"
          disabled
          style="margin-top: 49px;"
          v-model="textarea1"
        />
        <button type="button" class="btn btn-info" @click="copy1">
          コピー
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Chatbot_embedded",
  data() {
    return {
      textarea1: "",
      isShow: false,
      message: ""
    };
  },
  mounted() {
    let textCid =
      "var hachidori_popup_cid ='" + this.$store.getters.user.cid + "'";
    let startTextJS = '<script type="text/javascript">';
    let endTextJS = `<` + `/script>`;
    let chatText =
      '<script src="https://popup.hachidori.io/withdrawal/chatbot.js" type="text/javascript">';
    this.textarea1 =
      startTextJS +
      "\n" +
      textCid +
      ";" +
      "\n" +
      this.$store.getters.chatbot +
      "\n" +
      endTextJS +
      "\n" +
      chatText +
      endTextJS;
  },
  methods: {
    copy1() {
      this.$copyText(this.textarea1).then(() => {
        this.isShow = true;
        this.message = "チャットボット表示用タグ";
        // 2秒後にアラート消す
        setTimeout(() => {
          this.isShow = false;
        }, 2000);
      });
    }
  }
};
</script>

<style scoped>
h3,
p {
  text-align: left;
}

h3 {
  font-weight: 700;
}

.form-control:disabled {
  background-color: white;
}

.form-control {
  border: 2px solid #ced4da;
  border-radius: 0.5rem;
  width: 75%;
}

.embedded {
  margin-left: 50px;
}

.btn {
  margin: 0 0 0 13rem;
  padding: 0.5rem 3rem;
  margin-top: 2rem;
}

.vif-enter-active,
.vif-leave-active {
  transition: opacity 2s;
}

.vif-enter,
vif-leave-to {
  opacity: 0;
}
</style>
