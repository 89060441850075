<template>
  <div class="notfound">
    <h2>お探しのページは見つかりませんでした。</h2>
  </div>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<style scoped>
.notfound {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 9999;
  padding-top: 5rem;
}
</style>
