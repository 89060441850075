<template>
  <div class="signup">
    <h2>アカウント発行</h2>
    <form @submit.prevent="singup">
      <div class="input-group input-group-lg">
        <input
          type="text"
          class="form-control"
          aria-label="ID input"
          aria-describedby="inputGroup-sizing-lg"
          placeholder="ID"
          v-model="username"
          required
        />
      </div>
      <div class="input-group input-group-lg">
        <input
          type="text"
          class="form-control"
          aria-label="Password input"
          aria-describedby="inputGroup-sizing-lg"
          placeholder="パスワード"
          v-model="password"
          required
        />
      </div>
      <div class="input-group input-group-lg">
        <input
          type="text"
          class="form-control"
          aria-label="Password input"
          aria-describedby="inputGroup-sizing-lg"
          placeholder="パスワード(確認)"
          v-model="passwordConfirm"
          required
        />
      </div>
      <div class="form-group">
        <select class="form-control" id="select" v-model="account_type">
          <option value="1">ポップアップ用アカウント</option>
          <option value="2">チャットボット用アカウント</option>
        </select>
      </div>
      <button
        type="button"
        class="btn btn-info"
        @click="singup"
        style="display: flex; margin-bottom: 50px;"
      >
        アカウントを作成
      </button>
      <button type="button" class="btn btn-info" @click="backReport">
        レポート画面へ戻る
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: "Signup",
  data() {
    return {
      username: "",
      password: "",
      passwordConfirm: "",
      account_type: "1"
    };
  },
  mounted() {
    if (this.$store.getters.user.role === "user") {
      this.$router.replace("/report");
    }
  },
  methods: {
    singup() {
      if (this.username && this.password === this.passwordConfirm) {
        const params = {
          status: "create",
          login_id: this.username,
          password: this.password,
          account_type: this.account_type
        };
        const options = {
          headers: { "Content-Type": "application/json;charset=utf-8" }
        };
        this.axios
          .post(
            "https://lelcv9fog7.execute-api.ap-northeast-1.amazonaws.com/prod/setUser",
            params,
            options
          )
          .then(() => {
            alert("ユーザー作成に成功しました。");
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    backReport() {
      this.$router.replace("/report");
    }
  }
};
</script>

<style scoped>
.signup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #dcdcdc;
  z-index: 9999;
  padding-top: 5rem;
}

h2 {
  margin-bottom: 2.5rem !important;
}

.input-group {
  width: 70%;
  margin: 0 auto;
}

.form-control {
  border-radius: 0.6rem !important;
  margin-bottom: 2.5rem !important;
}

.btn {
  margin: auto;
  padding: 0.6rem 5rem;
  font-weight: bold;
}

#select {
  width: 70%;
  margin: 0 auto;
}
</style>
