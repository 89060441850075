<template>
  <div class="confirm">
    <h2>確認コード入力</h2>
    <form @submit.prevent="confirm">
      <div class="input-group input-group-lg">
        <input
          type="text"
          class="form-control"
          aria-label="ID input"
          aria-describedby="inputGroup-sizing-lg"
          placeholder="ID"
          v-model="username"
          required
        />
      </div>
      <div class="input-group input-group-lg">
        <input
          type="password"
          class="form-control"
          aria-label="confirmation Code"
          aria-describedby="inputGroup-sizing-lg"
          placeholder="確認コード"
          v-model="confirmationCode"
          required
        />
      </div>
      <button type="button" class="btn btn-info" @click="confirm">
        確認
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: "Confirm",
  data() {
    return {
      username: "",
      confirmationCode: ""
    };
  },
  mounted() {
    if (this.$store.getters.user.role === "user") {
      this.$router.replace("/report");
    }
  },
  methods: {
    confirm() {
      this.$cognito
        .confirmation(this.username, this.confirmationCode)
        .then(result => {
          console.log(result);
          this.$router.replace("/login");
        })
        .then(err => {
          this.error = err;
        });
    }
  }
};
</script>

<style scoped>
.confirm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #dcdcdc;
  z-index: 9999;
  padding-top: 5rem;
}

h2 {
  margin-bottom: 2.5rem !important;
}

.input-group {
  width: 70%;
  margin: 0 auto;
}

.form-control {
  border-radius: 0.6rem !important;
  margin-bottom: 2.5rem !important;
}

.btn {
  margin: auto;
  padding: 0.6rem 5rem;
  font-weight: bold;
}
</style>
