<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ["chartData", "options"],
  mounted() {
    if (this.$data._chart) this.$data._chart.destroy(); // グラフを消す
    this.renderChart(this.chartData, this.options);
  }
};
</script>

<style scoped>
.chart {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

#bar-chart {
  width: 100%;
  height: 80%;
}
</style>
