<template>
  <div id="app">
    <Header />
    <Sidebar class="sidebar-area" />
    <div class="scafold-wrapper text-center">
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";

export default {
  name: "app",
  components: {
    Header,
    Sidebar
  },
  data() {
    return {
      showFullscreen: true
    };
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.sidebar-area {
  /* 左側に固定 */
  float: left;
}

.footer-area {
  margin-top: 40px;
}

.scafold-wrapper {
  /* display: flex; 要素を横並びにする */
  flex-direction: column; /* 要素の並び順の主軸を指定 上 => 下 */
  min-height: 100vh; /* 要素の高さの最小値を指定 vhはviewport(表示領域) heightの略 */

  /* サイドバーのwidth分だけ範囲を削除 */
  width: calc(100% - 250px);

  /* サイドバーで隠れるので右に寄せる */
  margin: 100px 100px 0 230px;
}

.btn-info {
  color: #fff !important;
  background-color: #22a7f0 !important;
  border-color: #22a7f0 !important;
}
</style>
