import Vue from "vue";
import Router from "vue-router";
import Login from "../views/Login";
import Account from "../views/Account.vue";
import Signup from "../views/Signup";
import Confirm from "../views/Confirm";
import Report from "../views/Report";
import Embedded from "../views/Embedded";
import Banner from "../views/Banner";
import Link from "../views/Link";
import Chatbot_embedded from "../views/Chatbot_embedded.vue";
import Chatbot_Report from "../views/Chatbot_Report.vue";
import Chatbot_Link from "../views/Chatbot_Link.vue";
import Chatbot from "../views/Chatbot.vue";
import TimeSetting from "../views/TimeSetting";
import NotFound from "../views/NotFound";
import store from "../store/index";

Vue.use(Router);

const requireAuth = (to, from, next) => {
  if (store.getters.user.login_status === 1) {
    next();
  } else {
    next({
      path: "/login",
      query: { redirect: to.fullPath }
    });
  }
};

const logout = (to, from, next) => {
  next("/login");
};

export default new Router({
  mode: "history",
  routes: [
    { path: "/", redirect: "login" },
    {
      path: "/account",
      name: "Account",
      component: Account,
      beforeEnter: requireAuth
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/singup",
      name: "Signup",
      component: Signup,
      beforeEnter: requireAuth
    },
    {
      path: "/confirm",
      name: "Confirm",
      component: Confirm,
      beforeEnter: requireAuth
    },
    {
      path: "/report",
      name: "Report",
      component: Report,
      beforeEnter: requireAuth
    },
    {
      path: "/embedded",
      name: "Embedded",
      component: Embedded,
      beforeEnter: requireAuth
    },
    {
      path: "/banner",
      name: "Banner",
      component: Banner,
      beforeEnter: requireAuth
    },
    {
      path: "/link",
      name: "Link",
      component: Link,
      beforeEnter: requireAuth
    },
    {
      path: "/chatbot_embedded",
      name: "Chatbot_embedded",
      component: Chatbot_embedded,
      beforeEnter: requireAuth
    },
    {
      path: "/chatbot_report",
      name: "Chatbot_Report",
      component: Chatbot_Report,
      beforeEnter: requireAuth
    },
    {
      path: "/chatbot_link",
      name: "Chatbot_Link",
      component: Chatbot_Link,
      beforeEnter: requireAuth
    },
    {
      path: "/chatbot",
      name: "Chatbot",
      component: Chatbot,
      beforeEnter: requireAuth
    },
    {
      path: "/time",
      name: "TimeSetting",
      component: TimeSetting,
      beforeEnter: requireAuth
    },
    { path: "/logout", beforeEnter: logout },
    {
      name: "NotFound",
      path: "*",
      component: NotFound,
      meta: { title: "お探しのページは見つかりませんでした。" }
    }
  ]
});
