<template>
  <div class="login">
    <div class="container">
      <loading :active.sync="isLoading" :is-full-page="fullPage" />
    </div>
    <h2>hachidori popup</h2>
    <form @submit.prevent="login">
      <div class="input-group input-group-lg">
        <input
          type="text"
          class="form-control"
          aria-label="ID input"
          aria-describedby="inputGroup-sizing-lg"
          placeholder="ID"
          v-model="username"
          required
        />
      </div>
      <div class="input-group input-group-lg">
        <input
          type="password"
          class="form-control"
          aria-label="Password input"
          aria-describedby="inputGroup-sizing-lg"
          placeholder="パスワード"
          v-model="password"
          required
        />
      </div>
      <button type="button" class="btn btn-info" @click="login">
        ログイン
      </button>
    </form>
    <!-- <router-link to="/confirm">確認コード入力</router-link>
    <router-link to="/singup">ユーザー登録</router-link> -->
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Login",
  props: {
    showFullscreen: Boolean
  },
  components: { loading: Loading },
  data() {
    return {
      username: "",
      password: "",
      isLoading: false,
      fullPage: true
    };
  },
  mounted() {
    if (this.$store.getters.user.login_id != null) {
      this.$router.replace("/report");
    }
  },
  methods: {
    login() {
      this.isLoading = true;
      if (this.username && this.password) {
        const params = {
          status: "login",
          login_id: this.username,
          password: this.password
        };
        const options = {
          headers: { "Content-Type": "application/json;charset=utf-8" }
        };
        this.axios
          .post(
            "https://lelcv9fog7.execute-api.ap-northeast-1.amazonaws.com/prod/setUser",
            params,
            options
          )
          .then(res => {
            let data = JSON.parse(res.data.body);
            // dataが文字列ならログイン失敗
            if (typeof data === "string") {
              alert(
                "ログインに失敗しました。\nIDとパスワードを確認してください。"
              );
              location.reload();
            } else {
              this.$store.commit("setUser", data);

              const params = {
                status: "update_login_status",
                login_id: this.username,
                password: this.password,
                login_status: 1
              };
              const options = {
                headers: { "Content-Type": "application/json;charset=utf-8" }
              };
              this.axios
                .post(
                  "https://lelcv9fog7.execute-api.ap-northeast-1.amazonaws.com/prod/setUser",
                  params,
                  options
                )
                .then(() => {
                  this.isLoading = false;
                  this.$router.replace("/report");
                })
                .catch(() => {
                  this.isLoading = false;
                });
            }
          });
      }
    }
  }
};
</script>

<style scoped>
.login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #dcdcdc;
  z-index: 9999;
  padding-top: 5rem;
}

h2 {
  margin-bottom: 2.5rem !important;
}

.input-group {
  width: 70%;
  margin: 0 auto;
}

.form-control {
  border-radius: 0.6rem !important;
  margin-bottom: 2.5rem !important;
}

.btn {
  margin: auto;
  padding: 0.6rem 5rem;
  font-weight: bold;
}
</style>
