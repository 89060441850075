import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: null,
    idToken: null,
    link: null,
    message: null,
    color: null,
    image_path: null,
    startDate: null,
    endDate: null
  },
  getters: {
    user: state => state.user,
    idToken: state => state.idToken,
    link: state => state.link,
    message: state => state.message,
    color: state => state.color,
    image_path: state => state.image_path,
    chatbot: state => state.chatbot,
    startDate: state => state.startDate,
    endDate: state => state.endDate
  },
  mutations: {
    // ユーザー情報保存
    setUser(state, user) {
      state.user = user;
    },
    // ユーザーidTokenを保存
    setidToken(state, idToken) {
      state.idToken = idToken;
    },
    setLink(state, link) {
      state.link = link;
    },
    setMessage(state, message) {
      state.message = message;
    },
    setColor(state, color) {
      state.color = color;
    },
    setImagePath(state, image_path) {
      state.image_path = image_path;
    },
    setChatbot(state, chatbot) {
      state.chatbot = chatbot;
    },
    setStartDate(state, startDate) {
      state.startDate = startDate;
    },
    setEndDate(state, endDate) {
      state.endDate = endDate;
    }
  },
  plugins: [createPersistedState()]
});

export default store;
