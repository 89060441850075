<template>
  <div class="banner">
    <div class="container">
      <loading :active.sync="isLoading" :is-full-page="fullPage" />
    </div>
    <div class="form-group">
      <input
        style="display: none"
        ref="input"
        type="file"
        class="form-control-file"
        accept=".jpg, .jpeg, .png"
        @change="fileUpload"
      />
    </div>
    <button type="button" class="btn btn-info" @click="openFile">
      ファイルを選択する
    </button>
    <p class="img_text">
      ※画像サイズ：350×280
    </p>
    <hr />
    <div v-if="isSet">
      <p class="img_text-end">
        設定中画像のプレビュー
      </p>
      <img :src="url" />
    </div>
    <div v-else>
      <img src="../assets/pc.jpg" />
      <p class="img_text-start">
        まだ画像は設定されていません。
      </p>
    </div>
  </div>
</template>

<script>
import * as AWS from "aws-sdk";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "Banner",
  components: { loading: Loading },
  data() {
    return {
      isSet: false,
      url: "",
      uploadFile: null,
      payload: {},
      file: [],
      image: "",
      ready: false,
      data: null,
      isLoading: false,
      fullPage: true
    };
  },
  mounted() {
    this.isLoading = true;
    this.axios
      .get(
        "https://irua384lo0.execute-api.ap-northeast-1.amazonaws.com/prod/getTagInfo",
        {
          params: {
            cid: this.$store.getters.user.cid
          }
        }
      )
      .then(result => {
        if (result.data !== null) {
          let data = JSON.parse(result.data.body);
          this.url = data.image_path;
          this.$store.commit("setImagePath", data.image_path);
          this.isSet = true;
        } else {
          this.isSet = false;
        }
        this.isLoading = false;
      });
  },
  methods: {
    openFile() {
      this.$refs.input.click();
    },
    fileUpload() {
      this.isLoading = true;
      AWS.config.update({
        region: "ap-northeast-1",
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: "ap-northeast-1:4d49e62b-04a5-46fd-94ed-a2fed48314c5"
        })
      });
      this.file = this.$refs.input.files[0];
      let filename = this.$refs.input.files[0].name;
      let contentType = this.file.type;
      let photosKey = this.$store.getters.user.cid + "/";
      let photoKey = photosKey + filename;
      var upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: "hachidoribucket",
          Key: photoKey,
          Body: this.file,
          ACL: "public-read",
          ContentType: contentType
        }
      });
      let promise = upload.promise();

      promise.then(
        function(data) {
          const params = {
            cid: this.$store.getters.user.cid,
            image_path: data.Location
          };
          const options = {
            headers: { "Content-Type": "application/json;charset=utf-8" }
          };
          this.axios
            .post(
              "https://h0sov2149g.execute-api.ap-northeast-1.amazonaws.com/prod/setImageURL",
              params,
              options
            )
            .then(() => {
              //location.reload();
            })
            .catch(error => {
              console.error(error);
            });
          this.isLoading = false;
        }.bind(this),
        function(err) {
          console.error(err);
          this.isLoading = false;
          return alert("サーバーエラーが発生しました。");
        }
      );
    }
  }
};
</script>

<style scoped>
.btn,
.form-group {
  margin: auto;
  padding: 0.9rem 4rem;
  font-weight: bold;
}

.btn {
  margin-bottom: 5rem;
}

img {
  width: 30%;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.img_text {
  font-weight: 100;
  margin-top: -3.5rem;
  color: #808080;
}

.img_text-start {
  font-weight: bold;
  color: #808080;
  font-size: 1.3rem;
}
.img_text-end {
  font-weight: bold;
  color: #808080;
  font-size: 1.3rem;
  margin-top: 2rem;
}
</style>
