<template>
  <div class="time">
    <div class="time_section">
      <div class="container">
        <loading :active.sync="isLoading" :is-full-page="fullPage" />
      </div>
      <h3>タイマー設定</h3>
      <div class="box">
        <div class="timetitle">
          ユーザー未操作時発動時間
          <span class="ques ">?</span>
          <div class="ex">
            ・ユーザーがwebページ上で何も操作をしなかった場合、流入から何秒後に発動させるかを設定することが出来ます。
            <br />・0秒で設定するとユーザー未操作時の秒数指定でのポップアップを非表示にできます。
          </div>
        </div>
      </div>
      <div v-if="isNoOperation">
        <div class="input-group input-group-lg input1">
          <input
            type="number"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            v-model="noOperationTime"
            placeholder="現在未設定です。"
          />
          <button
            type="button"
            class="btn btn-info"
            @click="setNoOperationTime"
            v-bind:disabled="isPush1"
          >
            設定
          </button>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-sm-2">
            <label>設定中</label>
          </div>
          <div class="col-sm-3">
            <p class="noOperationTime">{{ noOperationTime }}秒</p>
          </div>
          <div class="col-sm-2">
            <button type="button" class="btn btn-info" @click="operationTime">
              編集
            </button>
          </div>
        </div>
      </div>
      <div>
        <div class="box">
          <div class="timetitle">
            ユーザー操作時発動時間
            <span class="ques ">?</span>
            <div class="ex">
              ・ユーザーがwebページ上で何らかの操作をした場合、流入から何秒後に発動させるかを設定することが出来ます。
              <br />・0秒で設定するとユーザー操作時の秒数指定でのポップアップを非表示にできます。
            </div>
          </div>
        </div>
      </div>
      <div v-if="isElapsedTime">
        <div class="input-group input-group-lg input1">
          <input
            type="number"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            v-model="elapsedTime"
            placeholder="現在未設定です。"
          />
          <button
            type="button"
            class="btn btn-info"
            @click="setElapsedTime"
            v-bind:disabled="isPush2"
          >
            設定
          </button>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-sm-2">
            <label>設定中</label>
          </div>
          <div class="col-sm-3">
            <p class="elapsedTime">{{ elapsedTime }}秒</p>
          </div>
          <div class="col-sm-2">
            <button type="button" class="btn btn-info" @click="elapsedTimes">
              編集
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "Time",
  components: { loading: Loading },
  data() {
    return {
      noOperationTime: 0,
      elapsedTime: 0,
      color: "#1CA085",
      textColor: "white",
      isNoOperation: true,
      isElapsedTime: true,
      isPreview: true,
      isLoading: false,
      fullPage: true
    };
  },
  computed: {
    isPush1() {
      return this.NoOperationTime();
    },
    isPush2() {
      return this.ElapsedTime();
    }
  },
  mounted() {
    this.isLoading = true;
    this.noOperationTime = this.$store.getters.user.no_operation_time;
    this.isNoOperation = false;

    this.elapsedTime = this.$store.getters.user.elapse_time;
    this.isElapsedTime = false;
    this.isLoading = false;
  },
  methods: {
    setNoOperationTime() {
      this.isNoOperation = false;
      const params = {
        status: "set_no_operation_time",
        login_id: this.$store.getters.user.login_id,
        no_operation_time: this.noOperationTime
      };
      const options = {
        headers: { "Content-Type": "application/json;charset=utf-8" }
      };
      this.axios
        .post(
          "https://f4i4ied5g2.execute-api.ap-northeast-1.amazonaws.com/prod/setTime",
          params,
          options
        )
        .then(res => {
          this.isLoading = false;
          let data = JSON.parse(res.data.body);
          this.$store.commit("setUser", data);
        })
        .catch(error => {
          console.error(error);
        });
    },
    setElapsedTime() {
      this.isElapsedTime = false;
      // this.$store.commit("elapsedTime", this.setElapsedTime);
      const params = {
        status: "set_elapse_time",
        login_id: this.$store.getters.user.login_id,
        elapse_time: this.elapsedTime
      };
      const options = {
        headers: { "Content-Type": "application/json;charset=utf-8" }
      };
      this.axios
        .post(
          "https://f4i4ied5g2.execute-api.ap-northeast-1.amazonaws.com/prod/setTime",
          params,
          options
        )
        .then(res => {
          this.isLoading = false;
          let data = JSON.parse(res.data.body);
          this.$store.commit("setUser", data);
        })
        .catch(error => {
          console.error(error);
        });
    },
    operationTime() {
      this.isNoOperation = true;
    },
    elapsedTimes() {
      this.isElapsedTime = true;
    },
    NoOperationTime() {
      if (this.noOperationTime !== "") {
        return false;
      } else {
        return true;
      }
    },
    ElapsedTime() {
      if (this.elapsedTime !== "") {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<style scoped>
.time {
  margin-left: 2rem;
}

.time_section {
  margin-bottom: 6rem;
}

h3 {
  text-align: left;
  font-weight: bold;
}

.timetitle {
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  margin-top: 2.5rem;
  color: #696767;
  margin-bottom: 15px;
}

.box {
  position: relative;
}
.ques {
  background: #22a7f0;
  border-radius: 50%;
  padding: 2px 6px;
}
.ques {
  font-size: 13px;
  color: #ffff;
}

.ex {
  position: absolute;
  top: 0;
  left: 250px;
  color: rgba(0, 0, 0, 0.719);
  font-size: 13px;
  /* 黒バックを半透明にするため、rgbaで指定 */
  padding: 2px 5px;
}

.ex {
  display: none;
  /*↓に変更*/
  opacity: 0;
  transition: all 0.3s;
}

.ques:hover + .ex {
  display: block;
  /*↓に変更*/
  opacity: 1;
}
.ques:hover {
  cursor: pointer; /* カーソルを指の形にする。 */
}

.ex {
  display: none; /* 最初は非表示にする。 */
}

.ques:hover + .ex {
  display: block; /* quesの上にカーソルが乗っている時だけ表示 */
}

.form-control {
  border-radius: 0.6rem !important;
  border: solid 2px #c0c0c0;
}

.form-control::placeholder {
  color: #dcdcdc;
}

.btn {
  border-radius: 0.6rem;
  margin-left: 0.5rem;
  padding: 0 1.7rem 0 1.7rem;
}

.input1 {
  margin-top: 0.8rem;
  width: 60%;
}

.input2 {
  margin-top: 0.8rem;
  width: 80%;
}

label {
  border: solid 1px cadetblue;
  border-radius: 6px;
  color: cadetblue;
  padding: 1px 2px 1px 2px;
}
</style>
